
import { Vue, Watch, Component, Emit, Prop } from 'vue-property-decorator';
import SpriteType from './const';
import CreateLevel from './CreateLevel.vue';

@Component({
  components: {
    CreateLevel
  }
})
export default class MapEditor extends Vue {

  // 获取 Props
  @Prop(Boolean) readonly existingMap: boolean
  @Prop() readonly mapData: GameMap

  // 精灵列表
  spriteList: GameSprite[] = [
    {
      name: '主角',
      type: SpriteType.Player,
      width: 64,
      height: 64,
      image: './assets/player.png'
    },
    {
      name: '传送门',
      type: SpriteType.Transmit,
      width: 64,
      height: 64,
      image: './assets/transmit.png'
    },
    {
      name: '石头',
      type: SpriteType.Stone,
      width: 64,
      height: 64,
      image: './assets/stone.png'
    },
    {
      name: '树精',
      type: SpriteType.Monster,
      width: 64,
      height: 64,
      image: './assets/shujing.png'
    },
    {
      name: '松鼠精',
      type: SpriteType.Monster,
      width: 64,
      height: 64,
      image: './assets/songshu.png'
    },
    {
      name: '南瓜精',
      type: SpriteType.Monster,
      width: 64,
      height: 64,
      image: './assets/nangua.png'
    },
    {
      name: '宝箱',
      type: SpriteType.Gift,
      width: 64,
      height: 64,
      image: './assets/treasure.png'
    }
  ];
  
  currentLevel: number = 0;
  createNewMap: boolean = false;
  dragItem: GameSprite = {};
  
  // 拖拽
  drag(ev, item) {
    this.dragItem = item;
  }
  // 允许放下拖拽
  allowDrop(ev) {
    const elements = document.querySelectorAll('.ondrag');
    for(let elem of elements)
      elem.classList.remove('ondrag');
    ev.target.classList.add('ondrag');
    ev.preventDefault();
  }
  // 放下事件
  drop(ev) {
    if(!this.dragItem.name) return ev.preventDefault();
    ev.target.innerHTML = `<img src="${this.dragItem.image}" width="${this.dragItem.width}" height="${this.dragItem.height}" />`;
    ev.preventDefault();
    const {name, type, width, height, image} = this.dragItem;
    const position = ev.target.getAttribute('x') + '-' + ev.target.getAttribute('y');
    this.$store.commit('addSprite', {
      position,
      sprite: {
        name,
        type,
        width,
        height,
        x: ev.target.getAttribute('x'),
        y: ev.target.getAttribute('y'),
        image,
      }
    });
    ev.target.setAttribute('data-key', position);
    this.dragItem = {};
  }
  // 删除精灵
  deleteSprite(ev) {
    let myev = {};
    for(let i of ev.path) {
      if(i.classList.contains('map-ground')) {
        myev = i;
        break;
      }
    }
    const evN = myev as HTMLDivElement;
    if(evN.getAttribute('data-key')) {
      this.$store.commit('deleteSprite', evN.getAttribute('data-key'));
      evN.removeAttribute('data-key');
      evN.innerHTML = `(${evN.getAttribute('x')}, ${evN.getAttribute('y')})`;
    }
  }

  /**
   * 关卡选择改变
   */
  selectChange() {
    this.mapData.levels[this.currentLevel].sprites.forEach((item) => {
      const position = `${this.currentLevel}-${item.x}-${item.y}`;
      let ev = document.querySelector(`.map-ground[data-ref="${position}"]`);
      ev.innerHTML = `<img src="${item.image}" width="${item.width}" height="${item.height}" />`;
      ev.setAttribute('data-key', `${this.currentLevel}-${item.x}-${item.y}`);
    })
  }
  /**
   * 监听关卡改变
   */
  @Watch('currentLevel')
  onCurrentLevelChange() {
    this.$store.commit('setCurrentLevel', this.currentLevel);
    this.$nextTick(() => {
      this.selectChange();
    });
  }

  /**
   * 监听是否为导入文件
   */
  get loadingFile() {
    return this.$store.state.loadingFile
  }
  @Watch('loadingFile')
  onMapDataUpChange() {
    if(this.$store.state.loadingFile == true) {
      this.$store.commit('setLoadingFile', false)
      this.$nextTick(() => {
        this.selectChange();
      });
    }
  }

  // 新建关卡
  @Emit('create-level')
  createLevel() {
    this.createNewMap = !this.createNewMap;
  }

  handleSave() {
    const replacer = (k, v) => {
      if(v instanceof Map) {
        return Array.from(v.entries());
      } else {
        return v;
      }
    };
    const json = JSON.stringify(this.$store.state.mapData, replacer);
    let link = document.createElement('a');
    link.download = 'map.json';
    let blob = new Blob([json], {type: 'application/json'});
    link.href = URL.createObjectURL(blob);
    link.click();
    URL.revokeObjectURL(link.href);
  }

}
