
/// <reference path="./app.d.ts" />
import { Vue, Component, Emit } from 'vue-property-decorator';
import CreateMap from './CreateMap.vue';
import MapEditor from './MapEditor.vue';

@Component({
  components: {
    CreateMap,
    MapEditor
  }
})
export default class App extends Vue {
  existingMap: boolean = false;

  @Emit('create-map-fn')
  createMapFn() {
    this.existingMap = true;
  }

  get mapData() {
    return this.$store.state?.mapData
  }

}
