
import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
import UploadFile from './UploadFile.vue';

@Component({
  components: {
    UploadFile
  }
})
export default class CreateMap extends Vue {
  @Prop() existingMap: boolean

  mapName = '';
  levelInput = {
    level: '1',
    gridWidth: '',
    gridHeight: '',
    x: '',
    y: ''
  };

  createMap() {
    if(this.mapName == '')
      return alert("请输入地图名称")
    for(let i in this.levelInput)
      if(this.levelInput[i] == '') return alert("请完成关卡 1 设置")
    const {gridWidth, gridHeight, x, y} = this.levelInput
    let map:GameLevel = {
      level: '1',
      gridWidth: gridWidth,
      gridHeight: gridHeight,
      x: x,
      y: y,
      sprites: new Map()
    }
    this.$store.commit('createMap', {name: this.mapName, map})
    this.mapName = ''
    this.levelInput.gridWidth = ''
    this.levelInput.gridHeight = ''
    this.levelInput.x = ''
    this.levelInput.y = ''
    this.$emit('create-map-fn')
  }

  @Emit('create-map-fn')
  createMapFn() {
    this.$emit('create-map-fn');
  }

}
